$fonts-body: -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif;
$fonts-titles: -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif;
$fonts-source: 'Source Code Pro', monospace;

$base: rgb(76, 88, 91);
$selected: rgb(70, 100, 105);
$text: rgb(244, 237, 211);
$inactive: rgb(165, 155, 145);
$links: rgb(165, 191, 204);
$highlight: rgb(95, 180, 230);
$dates: rgb(126, 153, 163);

// $base: #474853;
// $selected: #40414b;
// $text: #EDF5E1;
// $inactive: #AAA0A0;
// $links: #86B3D1;
// $highlight: #86B3D1;
// $dates: $text;

// $base: #2B2E36;
// $selected: #40423C;
// $text: #EDF5E1;
// $inactive: $text;
// $links: #72a3c8;
// $highlight: #b695a1;
// $dates: $highlight;

@mixin box-sizing($property) {
  -webkit-box-sizing: $property;
     -moz-box-sizing: $property;
          box-sizing: $property;
}

%ui-shadow { box-shadow : 0 1px 2px rgba(0, 0, 0, 0.5), 0 1px 3px rgba(0, 0, 0, 0.46); }
%text-shadow { text-shadow: 0 1px 2px rgba(0, 0, 0, 0.5), 0 1px 3px rgba(0, 0, 0, 0.46); }

%content-base {
  display: flex;
  flex-flow: column nowrap;
  flex-grow: 1;
  padding: 71px 15px 15px;
  background-color: $base;
  h1 {
    margin: 0;
    font-family: $fonts-titles;
    strong {
      color: $highlight;
      @extend %text-shadow;
    }
  }
}

%link-setup {
  a {
    transition: all .2s;
    color: $links;
    &:hover {
      @extend %ui-shadow;
      color: $base;
      background-color: $links;
    }
  }
}

%nav-animate {
  position: relative;
  display: block;
  padding: 15px 5px;
  margin: 0 5px;

  font-size: 1.1rem;
  font-weight: normal;
  text-transform: uppercase;
  text-decoration: none;
  &:after,&:before {
    transition: all .5s;
  }
  &:hover {
    @extend %text-shadow;
    box-shadow: none;
    background-color: transparent;
  }
  &.current {
    color: $highlight;
    box-shadow: 0px 1px 0px $highlight;
  }
  &.not-current {
    color: $inactive;
    &:hover {
      color: $text;
      &:after {
        width: 100%;
      }
    }
    &:after {
      position: absolute;
      bottom: -1px;
      left: 0;
      right: 0;
      margin: auto;
      width: 0%;
      content: '.';
      color: transparent;
      text-shadow: none;
      background: $highlight;
      height: 1px;
    }
  }
}

%li-animate {
  position: relative;
  z-index: 1;
  transition: all 0.3s ease-out;
  border-left: 1px solid $text;
  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(to right, $base, rgba(42,45,51,0));
    z-index: -1;
  }
  &:hover {
    border-left: 1px solid $highlight;
    background: $selected;
  }
}